<template>
  <main id="view-not-found" class="min-h-screen bg-gray-lightest pt-20">
    <div class="max-w-lg w-10/12 mx-auto">
      <ContentColumn>
        <ContentCard class="border-gray-light border py-4 px-8">
          <img
            src="@/assets/404-illustration.svg"
            alt="illustration of an empty office"
            class="mx-auto mb-4"
          />
          <h4 class=mb-2>Oops...</h4>
          <p class="mb-0">We must've taken a wrong turn.</p>
        </ContentCard>
      </ContentColumn>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import ContentColumn from '@/Common/ContentColumn'
import ContentCard from '@/Common/ContentCard'
export default {
  name: 'NotFound',
  components: {
    ContentCard,
    ContentColumn,
  },
}
</script>
