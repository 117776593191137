<template>
  <AuthenticationForm :id="`view-${formComponent}`">
    <component
      class="py-4 px-4 sm:px-8"
      v-bind:is="formComponent"
      :login-context="loginContext"
      :login-error="loginError"
      :loading="loggingIn"
      @login="login"
      @continue="doLoginAction"
    />
  </AuthenticationForm>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AuthenticationForm from '@/Common/AuthenticationFrom/AuthenticationForm'
import LoginForm from './Components/LoginForm'
import TotpForm from './Components/TotpForm'
import PushForm from './Components/PushForm'
export default {
  name: 'login-realms',
  components: {
    AuthenticationForm,
    LoginForm,
    TotpForm,
    PushForm,
  },
  computed: {
    ...mapState([
      'loginAction',
      'loginContext',
      'loginError',
      'loggingIn',
      'identity',
      'realmName',
      'ssoCache',
    ]),
    formComponent() {
      switch (this.loginAction) {
        case 'push-challenge':
          return 'push-form'
        case 'login-totp':
          return 'totp-form'
        default:
          return 'login-form'
      }
    },
  },
  methods: {
    ...mapActions(['login', 'doLoginAction']),
  },
}
</script>
