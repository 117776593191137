<template>
  <div>
    <div class="flex items-center justify-center mt-4 mb-6">
      <div class="h-8 w-8 mr-4">
        <ToznyLoader class="text-tozny w-8 h-8" />
      </div>
      <h3 class="text-base font-semibold">Waiting for TozID authentication</h3>
    </div>
    <hr class="border-gray-medium" />
    <div class="text-center -mt-4 mb-2">
      <span class="inline-block bg-white py-1 px-2 text-gray-dark">or</span>
    </div>
    <p class="text-center">scan this QR code using your TozID app</p>
    <div
      v-if="backupQrCode"
      v-html="backupQrCode"
      class="mx-auto my-4 w-48 h-48"
    ></div>
    <hr class="border-gray-medium" />
    <div class="text-center -mt-4 mb-2">
      <span class="inline-block bg-white py-1 px-2 text-gray-dark">or</span>
    </div>
    <h3 class="text-base font-semibold mb-6 text-center">
      TOTP Code
    </h3>
    <ToznyForm
      :error="errorMessage"
      :loading="loading"
      :onSubmit="submitTotpBackup"
      class="login-form"
    >
      <ToznyInput
        v-model="totp"
        id="totp"
        name="totp"
        type="tel"
        title="Numbers only"
        pattern="[0-9]+"
        autocomplete="off"
        class="mb-4 text-lil"
        placeholder="123456"
        label="Code"
        ref="totp"
        float-label
      />
      <ToznyButton
        id="login-button"
        class="login-button w-full py-3 rounded-lg text-lil"
        buttonText="Login"
        :loading="loading"
      />
    </ToznyForm>
  </div>
</template>

<script>
import ToznyLoader from '../../../Common/ToznyLoader'
import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import ToznyInput from '@/Common/ToznyInput'
import { mapActions } from 'vuex'
import qrCode from 'qrcode-generator'

export default {
  name: 'push-form',
  components: {
    ToznyLoader,
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loginError: String,
    loading: Boolean,
    loginContext: Object,
  },
  data() {
    return {
      poll: setInterval(() => {
        this.doPoll()
      }, 1000),
      totp: '',
    }
  },
  computed: {
    qrData() {
      return JSON.stringify({
        action: 'challenge',
        data: {
          question: 'Confirm authentication?',
          challenge: this.loginContext.challenge,
          challenge_id: this.loginContext.challenge_id,
          realm: this.loginContext.realm,
          username: this.loginContext.username,
        },
      })
    },
    backupQrCode() {
      if (!this.loginContext.challenge) {
        return ''
      }
      const qr = qrCode(0, 'L')
      qr.addData(this.qrData)
      qr.make()
      return qr
        .createSvgTag(100, 0)
        .replace(/(<svg .*?)( width=".*?" height=".*?")/, '$1')
    },
  },
  methods: {
    ...mapActions(['checkPushComplete']),
    async doPoll() {
      if (
        typeof this.loginContext === 'object' &&
        this.loginContext.challenge_id
      ) {
        const complete = await this.checkPushComplete(
          this.loginContext.challenge_id
        )
        if (complete) {
          this.clearPoll()
          this.$emit('continue', {})
        }
      }
    },
    async submitTotpBackup() {
      const totp = this.totp.trim()
      if (totp) {
        this.clearPoll()
        this.$emit('continue', { totp })
      }
    },
    clearPoll() {
      if (this.poll) {
        clearInterval(this.poll)
      }
      this.poll = false
    },
  },
  beforeDestroy() {
    this.clearPoll()
  },
}
</script>
