<template>
  <div>
    <h3 class="text-base font-semibold mb-6 text-center">
      Authenticator Code
    </h3>
    <ToznyForm
      :error="errorMessage"
      :loading="loading"
      :onSubmit="submitOtp"
      class="login-form"
    >
      <ToznyInput
        v-model="otp"
        id="otp"
        name="otp"
        type="tel"
        title="Numbers only"
        pattern="[0-9]+"
        autocomplete="off"
        class="mb-4 md:text-lil"
        placeholder="123456"
        label="Code"
        ref="totp"
        float-label
      />
      <ToznyButton
        id="login-button"
        class="login-button w-full py-3 rounded-lg text-lil"
        buttonText="Login"
        :loading="loading"
      />
    </ToznyForm>
  </div>
</template>

<script>
import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import ToznyInput from '@/Common/ToznyInput'

export default {
  name: 'totp-form',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loginError: String,
    loading: Boolean,
  },
  data: function() {
    return {
      otp: '',
      localError: '',
    }
  },
  computed: {
    errorMessage() {
      return this.loginError ? this.loginError : this.localError
    }
  },
  methods: {
    async submitOtp() {
      this.localError = ''
      const otp = this.otp.trim();
      if (otp) {
        this.$emit('continue', {otp})
      } else {
        this.localError = 'Please enter an authentication code.'
      }
    },
  },
  mounted() {
    // auto focus on the input element when the form loads.
    this.$refs.totp.$el.querySelector('#otp').focus();
  }
}
</script>
