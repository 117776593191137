<template>
  <div>
    <h3 class="text-base font-semibold mb-6 text-center">
      Sign in
    </h3>
    <ToznyForm
      :success="null"
      :error="errorMessage"
      :loading="loading"
      :onSubmit="btnLogin"
      class="login-form"
    >
      <ToznyInput
        v-model="username"
        id="username"
        name="username"
        class="mb-4 md:text-lil"
        :placeholder="'name@' + $route.params.realmName + '.com'"
        label="Username"
        float-label
      />
      <ToznyInput
        v-model="password"
        type="password"
        id="password"
        name="password"
        class="mb-6 md:text-lil"
        label="Password"
        float-label
      />
      <ToznyButton
        id="login-button"
        class="login-button w-full py-3 rounded-lg text-lil"
        buttonText="Login"
        :loading="loading"
      />
    </ToznyForm>
    <router-link class="text-tozny no-underline text-tiny" to="forgot"
      >Forgot password?</router-link
    >
  </div>
</template>

<script>
import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import ToznyInput from '@/Common/ToznyInput'

export default {
  name: 'login-form',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loginError: String,
    loading: Boolean,
  },
  data: function() {
    return {
      username: '',
      password: '',
      localError: '',
    }
  },
  computed: {
    errorMessage() {
      return this.loginError ? this.loginError : this.localError
    },
  },
  methods: {
    async btnLogin() {
      this.localError = ''
      const username = this.username.trim()
      const password = this.password
      if (username && password) {
        this.$emit('login', { username, password })
      } else {
        this.localError = 'Please enter your username and password.'
      }
    },
  },
}
</script>
